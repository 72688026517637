.contactPage{
    padding: 8rem 7rem;
    max-width: 900px;
    
    &-links{
        margin-bottom: 80px;
        max-height: 50px;
    }

    &-content{
        color: #FFF;
        h2{
            font-size: clamp(18px, 4vw, 36px);
            line-height: 1.25;
            font-weight: 400;
            margin-bottom: 40px; 

            font-family: 'Source Code Pro', monospace;
        }

        p{
            font-size: clamp(16px, 2vw, 24px);
            line-height: 1.75;
            font-weight: 400;
            margin-bottom: 40px; 

            font-family: 'Source Code Pro', monospace;
        }

        .email{
            font-size: clamp(16px, 2vw, 24px);
            line-height: 1.75;
            font-weight: 400;
            color: #FFF;
            display: block;
            
            transition: color .3s ease-in-out;

            font-family: 'Source Code Pro', monospace;

            &:hover{
                color: #FF828A;
        
                transition: color .3s ease-in-out;
            }
            margin-bottom: 40px;
        }

        ul{
            display: flex;
            font-family: 'Source Code Pro', monospace;

            li{
                margin: 0 20px;
                a{
                    color: white; 
                    transition: color .3s ease-in-out;
                    &:hover{
                        color: #FF828A;
                        transition: color .3s ease-in-out;
                    }

                    svg{
                        font-size: clamp(24px, 2vw, 36px);
                    }
                    
                }
            }

            li:first-child{
                margin-left: -10px;
            }
            
        }

    }
}

@media screen and (max-width: 768px) {
    .contactPage{
        padding: 3rem 1.8rem;
    }
}