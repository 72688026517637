.homePage{
    padding: 8rem 7rem;
    max-width: 900px;

    &-logo{
        img{
            max-width: 320px;
            max-height: 120px;
        }
    }

    &-welcome{
        color: #FFF;
        h1{
            margin: 40px 0;
            font-size: clamp(40px, 4vw, 66px);
            line-height: 1.25;
            font-weight: 400;
            font-family: 'Source Code Pro', monospace;
        }
        p{
            font-size: clamp(16px, 2vw, 24px);
            line-height: 1.75;
            font-weight: 400;
            margin-bottom: 4rem;
            font-family: 'Source Code Pro', monospace;
        }
    }
    
    &-links{
        display: flex;
        flex-wrap: wrap;
        gap: 35px;
    }
}

@media screen and (max-width: 768px) {
    .homePage{
        padding: 3rem 1.8rem;
    }
}