.linkButton{
    color: #FFF;
    border: 1px solid #FFF;
    padding: 25px 35px;
    font-weight: 400;
    font-size: clamp(16px, 2vw, 24px);
    text-decoration: none;

    transition: .3s all ease-in-out;

    font-family: 'Source Code Pro', monospace;
    &:hover{
        color: #FF828A;
        border-color: #FF828A;

        transition: .3s all ease-in-out;
    }
}

.sm{
    padding: 14px 30px;
    font-size: clamp(16px, 2vw, 18px);
}