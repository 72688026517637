.aboutPage{
    padding: 8rem 7rem;
    max-width: 900px;
    
    &-links{
        margin-bottom: 80px;
        max-height: 50px;
    }

    &-content{
        color: #FFF;
        h2{
            font-size: clamp(18px, 4vw, 36px);
            line-height: 1.25;
            font-weight: 400;
            margin: 40px 0;
            font-family: 'Source Code Pro', monospace;
        }

        &_team{
            display: flex;
            flex-wrap: wrap;
            gap: 35px;
            img{
                max-width: 177px;
            }
            margin-bottom: 40px;
        }

        p{
            font-size: clamp(16px, 2vw, 24px);
            line-height: 1.75;
            font-weight: 400;
            font-family: 'Source Code Pro', monospace;
        }
    }
}

@media screen and (max-width: 768px) {
    .aboutPage{
        padding: 3rem 1.8rem;
    }
}